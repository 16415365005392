<template>
  <div>

    <div class="card">
      <div class="card-body">

        <div class="position-relative wd-50">
          <input v-on:keyup.enter="loadVehicles()" type="text" class="form-control ps-5" v-model="filters.search" placeholder="Search Site..."> <span class="position-absolute top-50 product-show translate-middle-y"><i class="bx bx-search"></i></span>
        </div>

        <br/>

        <button type="button" class="btn btn-sm btn-light ms-2" style="float: right;" title="Manage Columns" data-bs-toggle="modal" data-bs-target="#manageColumnsModal">
          <i class="bx bxs-grid me-0"></i>
        </button>
        <button type="button" style="float: right"  data-bs-toggle="modal" data-bs-target="#createSiteModal" class="btn btn-sm btn-primary px-5">New Vehicle</button>
        <pagination @search="loadVehicles" :data="pagination"></pagination>

        <table id="example" class="table table-hover" style="width:100%">
          <thead>
          <tr>
            <th v-show="columns.id">ID</th>
            <th v-show="columns.registration">VRM</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.make">Make</th>
            <th v-show="columns.model">Model</th>
            <th v-show="columns.colour">Colour</th>
            <th v-show="columns.mot_date">MOT Date</th>
            <th v-show="columns.tax_date">Tax Date</th>
            <th v-show="columns.service_date">Service Date</th>
            <th v-show="columns.breakdown_cover">Breakdown Cover?</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="vehicles.length < 1">
            <td colspan="9" class="text-center">No vehicles found.</td>
          </tr>
          <tr v-for="vehicle in vehicles" :key="vehicle.id" style="cursor: pointer;" @click="$router.push({name: 'admin.vehicles.view', params: {id: vehicle.id}})">
            <td v-show="columns.id"># {{vehicle.id}}</td>
            <td v-show="columns.registration">{{vehicle.registration}}</td>
            <td v-show="columns.type">{{vehicle.type}}</td>
            <td v-show="columns.make">{{vehicle.make}}</td>
            <td v-show="columns.model">{{vehicle.model}}</td>
            <td v-show="columns.colour">{{vehicle.colour}}</td>
            <td v-show="columns.mot_date"><Time v-if="vehicle.mot_date !== null && vehicle.mot_date !== undefined" :datetime="vehicle.mot_date" format="dd/MM/yyyy"></Time></td>
            <td v-show="columns.tax_date"><Time v-if="vehicle.tax_date !== null && vehicle.tax_date !== undefined" :datetime="vehicle.tax_date" format="dd/MM/yyyy"></Time></td>
            <td v-show="columns.service_date"><Time v-if="vehicle.service_date !== null && vehicle.service_date !== undefined" :datetime="vehicle.service_date" format="dd/MM/yyyy"></Time></td>
            <td v-show="columns.breakdown_cover">{{vehicle.breakdown_cover || ""}}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <th v-show="columns.id">ID</th>
            <th v-show="columns.registration">VRM</th>
            <th v-show="columns.type">Type</th>
            <th v-show="columns.make">Make</th>
            <th v-show="columns.model">Model</th>
            <th v-show="columns.colour">Colour</th>
            <th v-show="columns.mot_date">MOT Date</th>
            <th v-show="columns.tax_date">Tax Date</th>
            <th v-show="columns.service_date">Service Date</th>
            <th v-show="columns.breakdown_cover">Breakdown Cover?</th>
          </tr>
          </tfoot>
        </table>

        <pagination @search="loadVehicles" :data="pagination"></pagination>
      </div>
    </div>

    <!-- MODALS -->

    <div class="modal fade" id="createSiteModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Create Vehicle</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-2">
                <p>Fields marked <span class="text-danger">*</span> are required</p>
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleRegistration" class="form-label">VRM <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleRegistration" v-model="vehicle.registration">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleVin" class="form-label">VIN Number</label>
                <input type="text" class="form-control" id="vehicleVin" v-model="vehicle.vin">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleMake" class="form-label">Make <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleMake" v-model="vehicle.make">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleModel" class="form-label">Model <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleModel" v-model="vehicle.model">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleColour" class="form-label">Colour <span class="text-danger">*</span></label>
                <input type="text" class="form-control" id="vehicleColour" v-model="vehicle.colour">
              </div>
              <div class="col-md-12 mb-2">
                <label for="vehicleType" class="form-label">Type <span class="text-danger">*</span></label>
                <select v-model="vehicle.type" id="vehicleType" :value="vehicle.type" class="form-control bg-dark">
                  <option value="Engineer">Engineer</option>
                  <option value="Office">Office</option>
                  <option value="Personal">Personal</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeCreateSiteModal'>Close</button>
            <submit-button type="button" :background="'btn btn-primary'" :clicked="creating" @click="createVehicle" text="Save changes"></submit-button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="manageColumnsModal" tabindex="-1" style="display: none;" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Manage Columns</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p>Please note these settings will be saved locally in your browser. If you clear your browser settings these settings may reset.</p>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="brandColumn" @change="updateColumnSettings" v-model="columns.id">
              <label class="form-check-label" for="brandColumn">ID</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="clientColumn" @change="updateColumnSettings" v-model="columns.registration">
              <label class="form-check-label" for="clientColumn">VRM</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="nameColumn" @change="updateColumnSettings" v-model="columns.make">
              <label class="form-check-label" for="nameColumn">Make</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="streetColumn" @change="updateColumnSettings" v-model="columns.model">
              <label class="form-check-label" for="streetColumn">Model</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="townColumn" @change="updateColumnSettings" v-model="columns.colour">
              <label class="form-check-label" for="townColumn">Colour</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="countyColumn" @change="updateColumnSettings" v-model="columns.mot_date">
              <label class="form-check-label" for="countyColumn">MOT Date</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="postcodeColumn" @change="updateColumnSettings" v-model="columns.tax_date">
              <label class="form-check-label" for="postcodeColumn">Tax Date</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="subnetColumn" @change="updateColumnSettings" v-model="columns.service_date">
              <label class="form-check-label" for="subnetColumn">Service Date</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="gatewayColumn" @change="updateColumnSettings" v-model="columns.breakdown_cover">
              <label class="form-check-label" for="gatewayColumn">Breakdown Cover</label>
            </div>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" id="typeColumn" @change="updateColumnSettings" v-model="columns.type">
              <label class="form-check-label" for="typeColumn">Vehicle Type</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id='closeManageColumnsModal'>Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* global $ */
import axios from 'axios';
import Pagination from '../../../components/Pagination.vue';
import SubmitButton from '../../../components/SubmitButton.vue';
import Time from '../../../components/Time.vue'
export default {
  components: { Pagination, SubmitButton, Time },
  name: 'admin.vehicles',
  data(){
    return {
      vehicles: [],
      pagination: {
        lastPage: 0,
        page: 0,
        showing: 0,
        total: 0
      },
      vehicle: {
        registration: "",
        make: "",
        model: "",
        colour: "",
        mot_date: "",
        tax_date: "",
        service_date: "",
        breakdown_cover: "",
        type: ""
      },
      creating: false,
      clients: [],
      filters: {
        search: ""
      },
      columns: {
        registration: true,
        make: true,
        model: true,
        colour: true,
        mot_date: true,
        tax_date: true,
        service_date: true,
        breakdown_cover: true,
        type: true
      }
    }
  },
  mounted(){
    this.loadVehicles();
    this.loadColumnSettings();
  },
  methods: {
    loadVehicles(page = 1){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/vehicles?page=${page}&search=${this.filters.search}`)
          .then(response => {
            this.vehicles = response.data.vehicles;
            this.pagination = response.data.pagination;
          })
          .catch(error => {
            this.$error('Failed to load vehicles.', error);
          });
    },
    createVehicle(){
      this.creating = true;
      axios.put(`${process.env.VUE_APP_API_URL}/v1/vehicles`, this.vehicle)
          .then(() => {
            $('#closeCreateSiteModal').click();
            this.$success("Created new vehicle");
            this.creating = false;
            this.vehicle = {
              registration: "",
              make: "",
              model: "",
              colour: "",
              mot_date: "",
              tax_date: "",
              service_date: "",
              breakdown_cover: "",
              type: ""
            }
            this.loadVehicles(this.pagination.page);
          })
          .catch(error => {
            this.$error("Failed to create new vehicle", error);
            this.creating = false;
          })
    },
    updateColumnSettings(){
      localStorage.setItem('link::vehicles::columns', JSON.stringify(this.columns));
    },
    loadColumnSettings(){
      let savedColumns = localStorage.getItem('link::vehicles::columns');
      console.log(savedColumns);
      if(savedColumns !== undefined && savedColumns !== null && savedColumns !== 'null' && savedColumns !== ''){
        console.log('Updating columns');
        this.columns = JSON.parse(savedColumns);
      }
    },

  }
}
</script>